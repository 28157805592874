"use strict";
var toggleActiveClass = function (el) {
    var dropdown = el;
    if ((el.tagName === 'A' || el.tagName === 'BUTTON') && el.parentElement) {
        dropdown = el.parentElement;
    }
    el.addEventListener('click', function (event) {
        event.preventDefault();
        dropdown.classList.toggle('active');
    });
};
document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.dropdown > a').forEach(toggleActiveClass);
    document.querySelectorAll('.dropdown > button').forEach(toggleActiveClass);
    document.addEventListener('click', function (event) {
        document.querySelectorAll('.dropdown').forEach(function (el) {
            if (event.target instanceof HTMLElement && !el.contains(event.target)) {
                el.classList.remove('active');
            }
        });
    });
});
