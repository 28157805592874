"use strict";
exports.__esModule = true;
var lodash_1 = require("lodash");
document.addEventListener('scroll', function () {
    var logo = document.getElementById('main-logo');
    if (logo && document.body.getBoundingClientRect().top < 0) {
        logo.classList.remove('w-48');
        logo.classList.remove('lg:w-60');
        logo.classList.add('w-32');
    }
    else if (logo) {
        logo.classList.remove('w-32');
        logo.classList.add('w-48');
        logo.classList.add('lg:w-60');
    }
    var topMenu = document.querySelector('.main-top-menu');
    if (!topMenu) {
        return;
    }
    var dropdown = document.querySelector('.sticky-top-menu .dropdown.active') || document.querySelector('.sticky-top-menu .dropdown:hover');
    if (!dropdown) {
        return;
    }
    var dummyItem = dropdown.querySelector('.dummy-item');
    if (!dummyItem || !(dummyItem instanceof HTMLElement)) {
        return;
    }
    var currentSpan = parseInt(dummyItem.style.gridRowEnd.split(' ')[1]);
    var handler = function (span) {
        if (span <= 0) {
            dummyItem.style.gridRowEnd = "span none";
        }
        else {
            dummyItem.style.gridRowEnd = "span " + span;
        }
    };
    if (topMenu.getBoundingClientRect().top < 0 && currentSpan < 8) {
        var addInterval_1 = window.setInterval(function () {
            handler(currentSpan++);
            if (currentSpan > 8) {
                window.clearInterval(addInterval_1);
            }
        }, 1);
    }
    else if (topMenu.getBoundingClientRect().top >= 0 && currentSpan > 1) {
        var subInterval_1 = window.setInterval(function () {
            handler(currentSpan--);
            if (currentSpan <= 0) {
                window.clearInterval(subInterval_1);
            }
        }, 1);
    }
});
document.addEventListener('DOMContentLoaded', function () {
    var _a, _b;
    var container = document.querySelector('#desktop-search');
    if (!container) {
        return;
    }
    var searchForm = container.querySelector('#desktop-search form');
    if (!searchForm) {
        return;
    }
    var input = searchForm.querySelector('input');
    if (!input) {
        return;
    }
    (_a = container.querySelector('.reveal-button')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
        if (searchForm.classList.contains('flex')) {
            return;
        }
        searchForm.classList.remove('hidden');
        searchForm.classList.add('flex');
        searchForm.classList.remove('opacity-0');
        searchForm.classList.remove('scale-x-0');
        searchForm.classList.add('scale-x-100');
        input.focus();
        if (searchForm instanceof HTMLFormElement && container && container.parentElement) {
            searchForm.style.width = container.parentElement.getBoundingClientRect().width + "px";
        }
    });
    (_b = searchForm.querySelector('.close-button')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', function () {
        if (!searchForm.classList.contains('flex')) {
            return;
        }
        if (searchForm instanceof HTMLFormElement && container) {
            searchForm.style.width = '0px';
        }
        searchForm.classList.add('opacity-0');
        searchForm.classList.remove('flex');
        searchForm.classList.add('hidden');
    });
    window.addEventListener('resize', lodash_1.debounce(function () {
        // not visible
        if (!searchForm.classList.contains('flex')) {
            return;
        }
        if (searchForm instanceof HTMLFormElement && container && container.parentElement) {
            searchForm.style.width = container.parentElement.getBoundingClientRect().width + "px";
        }
    }, 150));
});
